import _ from 'lodash'
import commonNav from '../../content/nav.yml'
import docsNav from '../../content/docs/nav.yml'

const navSource = {
  commonNav,
  docsNav
}

const initNav = (type) => {
  const initDocsNav = parent => parent.children.forEach((item) => {
    item.parent = parent
    item.path = parent.path.concat(item)
    item.url = type === 'common' ? `/${item.id}.html` : `/${type}/${item.id}.html`
    if (item.children) initDocsNav(item)
  })

  const root = {
    parent: null,
    path: [],
    children: _.cloneDeep(navSource[`${type}Nav`]) 
  }

  initDocsNav(root)

  return root
}

const types = ['common', 'docs']
const data = {}

_.forEach(types, type => {
  data[type] = initNav(type)
})

export default data